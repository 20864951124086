<template>
  <div>
    <div class="flex flex-col space-y-4">
      <div class="flex flex-col gap-y-1">
        <h1 class="font-EffraM text-black text-2xl">Se connecter</h1>
        <span class="font-EffraR text-lg">Connectez-vous pour continuer</span>
      </div>

      <form
        v-on:submit.prevent="onLogin"
        class="w-full flex flex-col space-y-4"
      >
        <dok-input
          size="lg"
          d-placeholder="Email"
          d-type="email"
          :d-model.sync="data.email"
          label="Email : "
          :custom-class="errors.email ? 'border-red-500' : ''"
        >
          <template v-slot:leftIcon>
            <img src="/icons/mail.png" />
          </template>
        </dok-input>

        <dok-input
          size="lg"
          d-placeholder="Mot de passe"
          :d-type="!data.showPassword ? 'password' : 'text'"
          :d-model.sync="data.password"
          label="Mot de passe :"
          :custom-class="errors.password ? 'border-red-500' : ''"
        >
          <template v-slot:rightIcon>
            <img
              src="/icons/eye.png"
              class="cursor-pointer"
              @click="data.showPassword = !data.showPassword"
            />
          </template>
        </dok-input>

        <div class="flex justify-end">
          <router-link
            :to="{ name: 'forgetPassword' }"
            class="text-base font-EffraR text-rosDok-ultra"
            >Mot de passe oublié?</router-link
          >
        </div>

        <button
          :class="
            !data.password || !data.email
              ? `cursor-not-allowed bg-rosDok-lighter`
              : `cursor-pointer ${other.successLogIn || 'bg-rosDok-ultra'}`
          "
          class="
            h-12
            font-EffraR
            text-lg
            border-0
            rounded
            flex
            justify-center
            items-center
          "
          @click="!data.password || !data.email ? null : onLogin()"
        >
          <span
            class="flex items-center gap-x-2 text-rosDok-ultra uppercase"
            v-if="other.successLogIn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="#fd5b68"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M16 21v-2c0-1.06-.421-2.078-1.172-2.828C14.078 15.422 13.061 15 12 15H5c-1.06 0-2.078.421-2.828 1.172C1.422 16.922 1 17.939 1 19v2M8.5 11c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zM17 11l2 2 4-4"
              />
            </svg>
            Connected
          </span>

          <span
            v-if="!other.successLogIn"
            :class="
              !data.password || !data.email ? 'text-rosDok-ultra' : 'text-white'
            "
          >
            Se Connecter
          </span>
        </button>
      </form>

      <div class="flex gap-x-1 text-lg justify-center">
        <span class="font-EffraR text-black">Pas encore inscrit?</span>
        <a href="#" class="text-rosDok-ultra font-EffraM" @click="goSignUp"
          >Créez un compte</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      data: { showPassword: false },
      errors: {},
      other: { successLogIn: false }
    };
  },
  methods: {
    ...mapActions("user", ["PATIENT_LOG_IN"]),
    goSignUp() {
      EventBus.$emit("TO-SIGNUP-PAGE");
    },
    async onLogin() {
      event.preventDefault();
      // this.$vs.loading();
      const payload = { email: this.data.email, password: this.data.password };
      // NEW CODE

      const {
        ok,
        data,
        message,
        errCode,
        token,
        user
      } = await this.PATIENT_LOG_IN(payload);

      if (!ok) {
        if (errCode === "UNVERIFIED_EMAIL") {
          // Check Email if not verified
          return this.$router.push({
            name: "verficationEmail",
            query: { token, email: payload.email }
          });
        }

        if (errCode === "UNVERIFIED_MOBILE") {
          // console.log("MOBILE", token);
          // Check Mobile if not verified
          return EventBus.$emit("Mobile_Verify_Patient_Not_Veriferd", token);
        }

        if (errCode === "UNVERIFIED_IDENTITY") {
          // console.log("CIN", token);
          // Check Identity if not verified
          return EventBus.$emit("BOOKING-ACCOUNT-NOT-VERIFY", token);
        }

        return EventBus.$emit("flashDok", {
          type: "errorr",
          message: message
        });
      }

      if (ok) {
        localStorage.setItem("accessToken", data.token);
        localStorage.setItem("userInfo", JSON.stringify(user));
        if (
          localStorage.getItem("userInfo") &&
          localStorage.getItem("accessToken")
        ) {
          EventBus.$emit("Emit_Success_Login");

          const dataAppointment = await this.$TAKE_APPOINTMENT();
          if (dataAppointment.ok) {
            EventBus.$emit(
              "IS_DONE_BOOKING",
              dataAppointment.data.category.title
            );
          }

          this.$forceUpdate();
        }
      }
    }
  }
};
</script>

<style></style>
